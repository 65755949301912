import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import Container from '@/components/atoms/Container';
import Typography from '@/components/atoms/Typography';
import Img from '@/components/atoms/Img';

import mq from '@/styles/mq';

import BackgroundDataType from '@/types/data/background';

interface FabricationProps {
  title: React.ReactNode;
  content: React.ReactNode;
  background: BackgroundDataType;
}

interface Props {
  fabrication: FabricationProps[];
}

const FabricationPresentationRoot = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  ${mq('xs')} {
    padding-top: ${({ theme }) => theme.spacing(5)};
  }
  ${mq('md')} {
    flex-direction: column !important;
    justify-content: space-between;
  }
`;

const FabricationItemRoot = styled.section`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(7)};
  ${mq('md')} {
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    &:nth-of-type(odd) {
      flex-direction: row !important;
      > div:nth-of-type(1) {
        padding-right: ${({ theme }) => theme.spacing(12)};
        padding-left: ${({ theme }) => theme.spacing(12)};
      }
      > div:nth-of-type(2) {
        padding-right: ${({ theme }) => theme.spacing(12)};
      }
    }
    &:nth-of-type(even) {
      flex-direction: row-reverse !important;
      > div:nth-of-type(1) {
        padding-right: ${({ theme }) => theme.spacing(12)};
        padding-left: ${({ theme }) => theme.spacing(12)};
      }
      > div:nth-of-type(2) {
        padding-left: ${({ theme }) => theme.spacing(12)};
      }
    }
  }
`;

const SectionLeft = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(5)};
  img {
    width: 100%;
    height: 330px;
    border-radius: 10px;
  }
  ${mq('xxs')} {
    img {
      width: 300px;
      height: 330px;
    }
  }
`;

const SectionRight = styled.div`
  /* width: 40%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Content = styled(Typography)`
  opacity: 0.7;
`;

const FabricationItem = ({
  title,
  content,
  background,
}: FabricationProps): JSX.Element => {
  return (
    <FabricationItemRoot>
      <SectionLeft>
        <Img src={background?.url || ''} />
      </SectionLeft>
      <SectionRight>
        <Title variant="h3" color="white">
          {title}
        </Title>
        <Content as="div" variant="textMd" color="white">
          {content}
        </Content>
      </SectionRight>
    </FabricationItemRoot>
  );
};

const FabricationPresentation = ({ fabrication }: Props): JSX.Element => {
  return (
    <FabricationPresentationRoot maxWidth="xl">
      {fabrication.map((fab, index) => {
        const { title, content, background } = fab;
        return (
          <FabricationItem
            key={index.toString()}
            title={title}
            content={content}
            background={background}
          />
        );
      })}
    </FabricationPresentationRoot>
  );
};

export const query = graphql`
  fragment FabricationPresentation on PrismicFabricationPageDataType {
    fabrication {
      fabrication_title {
        raw
      }
      fabrication_content {
        raw
      }
      fabrication_background {
        url
        alt
      }
    }
  }
`;

export default FabricationPresentation;
