import React from 'react';
import { graphql } from 'gatsby';

import styled from '@emotion/styled';

import Grid from '@/components/atoms/Grid';
import Container from '@/components/atoms/Container';
import Typography from '@/components/atoms/Typography';

import mq from '@/styles/mq';

interface Props {
  title?: React.ReactNode;
  content?: React.ReactNode;
}

const FabricationHeroRoot = styled(Container)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* padding-right: ${({ theme }) => theme.spacing(5)}; */
  /* padding-left: ${({ theme }) => theme.spacing(5)}; */
  padding-top: ${({ theme }) => theme.spacing(5)};
  /* padding-bottom: ${({ theme }) => theme.spacing(5)}; */
  ${mq('md')} {
    /* padding-right: ${({ theme }) => theme.spacing(27)}; */
    /* padding-left: ${({ theme }) => theme.spacing(27)}; */
    /* padding-left: ${({ theme }) => theme.spacing(33)}; */
    padding-top: ${({ theme }) => theme.spacing(15)};
    /* padding-bottom: ${({ theme }) => theme.spacing(2)}; */
    padding-right: ${({ theme }) => theme.spacing(12)};
    padding-left: ${({ theme }) => theme.spacing(12)};
  }
`;

const Title = styled(Typography)`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.color.white.main};
  padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Content = styled(Typography)`
  opacity: 0.7;
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  ${mq('xs')} {
    margin-bottom: ${({ theme }) => theme.spacing(6)};
  }
`;

const FabricationHero = ({ title, content }: Props): JSX.Element => {
  return (
    <FabricationHeroRoot
      //container
      // flexDirection="row"
      // flexWrap="nowrap"
      // alignItems="stretch"
      maxWidth="xl"
    >
      <Title variant="h1">{title}</Title>
      <Content as="div" color="white">
        {content}
      </Content>
    </FabricationHeroRoot>
  );
};

export const query = graphql`
  fragment FabricationHero on PrismicFabricationPageDataType {
    hero_title {
      raw
    }
    hero_content {
      raw
    }
  }
`;

export default FabricationHero;
