import React from 'react';
import { RichText } from 'prismic-reactjs';
import styled from '@emotion/styled';

import FabricationHero from '@/containers/Fabrication/FabricationHero';
import FabricationPresentation from '@/containers/Fabrication/FabricationPresentation';
import FabricationContactBox from '@/containers/Fabrication/FabricationContactBox';
import FabricationStats from '@/containers/Fabrication/FabricationStats';

import {
  FabricationPageQuery,
  PrismicFabricationPageFabricationGroupType,
  PrismicFabricationPageBodyContactbox,
  PrismicFabricationPageBody1Companystats,
} from '../../../graphql-types';

interface Props {
  data: {
    prismicFabricationPage: FabricationPageQuery;
    prismicFabricationPageBodyContactbox: PrismicFabricationPageBodyContactbox;
    prismicFabricationPageBody1Companystats: PrismicFabricationPageBody1Companystats;
  };
}

const FabricationContainerRoot = styled.section`
  background-color: ${({ theme }) => theme.color.black.main};
  padding-bottom: ${({ theme }) => theme.spacing(5)};
`;

const FabricationContainer = ({ data }: Props): JSX.Element => {
  const { hero_title, hero_content, fabrication } =
    data?.prismicFabricationPage?.data;

  const { primary } = data?.prismicFabricationPageBodyContactbox || {};

  const companyStats = data?.prismicFabricationPageBody1Companystats || {};

  const HeroProps = {
    title: <RichText render={hero_title?.raw} />,
    content: <RichText render={hero_content?.raw} />,
  };

  const FabricationProps = {
    fabrication: fabrication?.map(
      (el: PrismicFabricationPageFabricationGroupType | null) => {
        const {
          fabrication_title,
          fabrication_content,
          fabrication_background,
        } = el || {};

        return {
          title: <RichText render={fabrication_title?.raw} />,
          content: <RichText render={fabrication_content?.raw} />,
          background: {
            url: fabrication_background?.url,
            alt: fabrication_background?.alt,
          },
        };
      },
    ),
  };

  const ContactBoxProps = {
    title: <RichText render={primary?.title?.raw} />,
    content: <RichText render={primary?.content?.raw} />,
    button: {
      text: <RichText render={primary?.button_name?.raw} />,
      link: primary?.link,
    },
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
  };

  const StatsProps = {
    stats: companyStats?.items?.map((item) => ({
      number: <RichText render={item?.number?.raw} />,
      label: item?.label?.text,
    })),
  };

  return (
    <FabricationContainerRoot>
      <FabricationHero {...HeroProps} />
      <FabricationPresentation {...FabricationProps} />
      {primary && <FabricationContactBox {...ContactBoxProps} />}
      {companyStats?.items?.length > 0 && <FabricationStats {...StatsProps} />}
    </FabricationContainerRoot>
  );
};

export default FabricationContainer;
